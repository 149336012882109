import revive_payload_client_cQlwMIRnVm from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_tl5jf44bgnkhutl2oigfo6r6ry/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7kXjF8qd0W from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_tl5jf44bgnkhutl2oigfo6r6ry/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qCd6qIzfIO from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_tl5jf44bgnkhutl2oigfo6r6ry/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_CUMzSJCRps from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_tl5jf44bgnkhutl2oigfo6r6ry/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_zfpVanNbBA from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_tl5jf44bgnkhutl2oigfo6r6ry/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_TMNOqnaVZS from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_tl5jf44bgnkhutl2oigfo6r6ry/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Rxl9BQtA5x from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_tl5jf44bgnkhutl2oigfo6r6ry/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hjvCkaMotf from "E:/my_project/blog_nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "E:/my_project/blog_nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_6zpky9VBDg from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_tl5jf44bgnkhutl2oigfo6r6ry/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_liyViRA2ax from "E:/my_project/blog_nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "E:/my_project/blog_nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_odqmM0irX1 from "E:/my_project/blog_nuxt/node_modules/.pnpm/nuxt-primevue@3.0.0_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "E:/my_project/blog_nuxt/.nuxt/unocss.mjs";
import error_handler_kEP5FliEXj from "E:/my_project/blog_nuxt/plugins/error-handler.ts";
export default [
  revive_payload_client_cQlwMIRnVm,
  unhead_7kXjF8qd0W,
  router_qCd6qIzfIO,
  payload_client_CUMzSJCRps,
  navigation_repaint_client_zfpVanNbBA,
  check_outdated_build_client_TMNOqnaVZS,
  chunk_reload_client_Rxl9BQtA5x,
  plugin_vue3_hjvCkaMotf,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6zpky9VBDg,
  plugin_liyViRA2ax,
  primevue_plugin_egKpok8Auk,
  plugin_client_odqmM0irX1,
  unocss_MzCDxu9LMj,
  error_handler_kEP5FliEXj
]